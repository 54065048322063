[aria-hidden="true"] [tabindex]:focus {
    outline: none; /* Optionally remove focus outline for better visual experience */
    pointer-events: none;
}

.caseslider .slick-initialized .slick-slide {padding: 0px 0.5px;}
.caseslider .slick-list, .caseslider .slick-track, .caseslider .slick-slide>div {height: 100%;margin-top: 0px;}
.caseslider .slick-next {right: 0px;
    z-index: 1;
    height: 100%;
    width: 40px;
    /* background: #ffffff1a; */
}
.caseslider .slick-prev {left:0px;z-index: 1;height: 100%;
    width: 40px;
    /* background: rgba(255,255,255,.1); */
}
.caseslider .current_active {
    transition: max-height .5s ease-out 0s;
}
/* .caseslider .slick-slide.slick-active.slick-current .current_active {    
    max-height: 500px;
    transition: max-height .5s ease-in;
    padding-bottom: 10px;
} */
.caseslider .slick-initialized .slick-slide>div>div {height: 100%;}
.caseslider .slick-next, .caseslider .slick-prev {background: transparent;}
.caseslider .slick-slide span {display: none;}
.caseslider .slick-slide.slick-active.slick-current span {display: none;}

@media screen and (max-width:768px) {
    .caseslider .slick-next,
    .caseslider .slick-prev {width: 30px;}
}